import React, { useState } from "react"
import EarlyAccess from "../forms/earlyAccess"
import { Link } from "gatsby"
import cellFull from "../../images/gif/cell-full.gif"

const Hero = () => {
  const [formVisible, toggleForm] = useState(false)
  return (
    <header>
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-screen-xl mx-auto ">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
            {/*<svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>*/}
            <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h2
                  id="home"
                  className="text-4xl text-center tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl"
                >
                  A New Way To Gain
                  <br />
                  <span className="text-green"> Data Insights </span>
                  <br />
                </h2>
                <p className="mt-3 text-base text-center text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Create chart snippets of your saved files. Fast and easy.
                </p>
                {formVisible ? (
                  <div className="">
                    <EarlyAccess />
                  </div>
                ) : (
                  <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                    <div className="rounded-md shadow">
                      <Link
                        to="/buy/"
                        className="gradient w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-bold rounded-md text-white hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                      >
                        Buy Explot
                      </Link>
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a href="https://demo.explot.io/">
                        <Link
                          to="/tools/file-info/"
                          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green bg-indigo-100 hover:text-blue hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                        >
                          Try Demo
                        </Link>
                      </a>
                    </div>
                  </div>
                )}
                <div className="mt-8">
                  <img
                    src={cellFull}
                    className="shadow-custom m-0 p-0 mx-auto rounded"
                  />
                  <p className="mt-3 text-base text-center text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-sm lg:mx-0">
                    Create small cells that visualises your data and combine
                    multiple cells to view it in a new way and answer questions
                    through data.
                  </p>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Hero
