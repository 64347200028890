import React from "react"
import viewChart from "../../images/cell-view-chart.svg"

/**
 * Future Redesign
 * Create an image of an excel file (like in https://stripe.com/en-se/sigma but with excel instead of sql)
 * as step one -> visualize the remaining steps in the same way
 */
const GettingStarted = () => {
  return (
    <section className="py-8 bg-gray-200">
      <h2 id="showcase" className="text-center">
        How to visualize your data in 3 easy steps
      </h2>
      <p className="text-center text-gray-700">
        Start gaining insights into your data by visualizing and exploring it.
      </p>
      <ul className="md:grid md:grid-cols-3 md:divide-x md:divide-gray-500 p-4">
        <li className="px-8">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-dark-green text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M9 10V8h2v2h2v2h-2v2H9v-2H7v-2h2zm-5 8h12V6h-4V2H4v16zm-2 1V0h12l4 4v16H2v-1z" />
            </svg>
          </div>
          <h3 className="text-center mt-4">Add your data</h3>
          <p>
            Add or connect the different datasets or files that you want to
            explore.
          </p>
        </li>
        <li className="px-8">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-dark-green text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M4.13 12H4a2 2 0 1 0 1.8 1.11L7.86 10a2.03 2.03 0 0 0 .65-.07l1.55 1.55a2 2 0 1 0 3.72-.37L15.87 8H16a2 2 0 1 0-1.8-1.11L12.14 10a2.03 2.03 0 0 0-.65.07L9.93 8.52a2 2 0 1 0-3.72.37L4.13 12zM0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4z" />
            </svg>
          </div>
          <h3 className="text-center mt-4">Pick a chart type</h3>
          <p>Choose how you want to represent your data by picking a chart.</p>
        </li>
        <li className="px-8">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-dark-green text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M1 1h2v2H1V1zm0 4h2v2H1V5zm0 4h2v2H1V9zm0 4h2v2H1v-2zm0 4h2v2H1v-2zM5 1h2v2H5V1zm0 8h2v2H5V9zm0 8h2v2H5v-2zM9 1h2v2H9V1zm0 4h2v2H9V5zm0 4h2v2H9V9zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-16h2v2h-2V1zm0 8h2v2h-2V9zm0 8h2v2h-2v-2zm4-16h2v2h-2V1zm0 4h2v2h-2V5zm0 4h2v2h-2V9zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" />
            </svg>
          </div>
          <h3 className="text-center mt-4">Choose your chart dimensions</h3>
          <p>
            Once you got your data and chart in order you can pick what data
            will be shown by choosing what column will be X and what column will
            be Y.
          </p>
        </li>
      </ul>
    </section>
  )
}

const HowItWorks = () => {
  return (
    <section>
      <h2>How It Works</h2>
      {/* List the usual workflow: add dataset -> choose x,y -> choose chart -> visualize it! */}
      <ul>
        <li>
          <p>Add one or more datasets</p>
          <img src="" />
        </li>
        <li>
          <p>View the columns and choose which one represent the axis</p>
          <img src="" />
        </li>
        <li>
          <p>Choose how you want to visualize it</p>
          <img src="" />
        </li>
        <p>
          <p>Analyse it!</p>
          <img src="" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M4.13 12H4a2 2 0 1 0 1.8 1.11L7.86 10a2.03 2.03 0 0 0 .65-.07l1.55 1.55a2 2 0 1 0 3.72-.37L15.87 8H16a2 2 0 1 0-1.8-1.11L12.14 10a2.03 2.03 0 0 0-.65.07L9.93 8.52a2 2 0 1 0-3.72.37L4.13 12zM0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4z" />
          </svg>
        </p>
      </ul>
    </section>
  )
}

export default GettingStarted
