import React from "react"
import { Link } from "gatsby"

const CTA = ({ type }) => {
  if (type === "learn-more") {
    return (
      <section className="p-8 my-8">
        <div className="mx-auto w-4/5 md:w-1/2">
          <h2 className="font-normal text-blue my-3">Want to learn more?</h2>
          <h2 className="text-base leading-6 font-normal my-1">
            Get in contact with our founder or a sales representative and learn
            more about Explot.
          </h2>
          <button className="my-2 items-center shadow justify-center px-2 py-1 border border-transparent text-base leading-6 rounded text-white bg-light-blue hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2">
            <Link to="/learn-more/" state={{ learnMore: true }}>
              Book now
            </Link>
          </button>
        </div>
      </section>
    )
  }
  if (type === "contact") {
    return (
      <section className="p-8 my-8">
        <div className="mx-auto w-1/2">
          <h2 className="font-normal text-blue my-3">Contact us</h2>
          <h2 className="inline-block text-base leading-6 font-normal my-1">
            For any inquiries or feedback email us.{" "}
          </h2>
          <button className="ml-10 items-center shadow justify-center px-2 py-1 border border-transparent text-base leading-6 rounded text-white bg-light-blue hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2">
            <Link to="/sign-up" state={{ contact: true }}>
              Get in touch
            </Link>
          </button>
        </div>
      </section>
    )
  }
  if (type === "trial") {
    return (
      <section className="p-8 my-8">
        <div className="mx-auto w-1/2">
          <h2 className="font-normal text-blue my-3">Free trial</h2>
          <h2 className="inline-block text-base leading-6 font-normal my-1">
            Signup to our newsletter and get a 14-day free trial of Explot
          </h2>
          <button className="ml-10 items-center shadow justify-center px-2 py-1 border border-transparent text-base leading-6 rounded text-white bg-light-blue hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2">
            Sign Up
          </button>
        </div>
      </section>
    )
  }
  if (type === "notify") {
    return (
      <section className="md:p-8 my-8">
        <div className="mx-auto w-4/5 md:w-1/2 shadow-custom p-4 rounded-md">
          <h2 className="font-normal text-blue my-3 text-center">
            Interested?{" "}
            <span className="inline-block text-base text-black leading-6 font-normal my-1">
              {" "}
              Get notified when explot goes live.
            </span>
          </h2>
          <form
            className="w-full max-w-sm py-2 mx-auto"
            name="notify"
            method="POST"
            //@ts-ignore
            data-netlify="true"
          >
            <div className="md:flex items-center">
              <input
                type="hidden"
                className="hidden"
                name="form-name"
                value="notify"
              />
              <input
                className="py-4 md:py-2 appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                name="email"
                placeholder="Your email"
                aria-label="Email Adress"
              />
              <input
                className="flex-shrink-0 bg-light-blue hover:shadow-xl border-light-blue hover:text-black text-sm border-4 text-white py-1 px-2 rounded"
                type="submit"
                value="Notify Me"
              />
            </div>
            <label className="md:w-2/3 block text-gray-500 font-bold mr-3 px-2">
              <input
                className="mr-2 leading-tight"
                name="beta-access"
                type="checkbox"
              />
              <span className="text-sm">I'm open for beta access!</span>
            </label>
          </form>
        </div>
      </section>
    )
  }
  return (
    <section className="p-8 my-8">
      <div className="mx-auto w-4/5 md:w-1/2">
        <h2 className="font-normal text-green my-3">Ready to use Explot?</h2>
        <h2 className="inline-block text-base leading-6 font-normal my-1">
          Activate a free trial for your business or learn more below
        </h2>
        <button className="md:ml-10 items-center shadow justify-center px-2 py-1 border border-transparent text-base leading-6 rounded text-white bg-dark-green hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2">
          <Link to="/trial/" state={{ trial: true }}>
            Start now
          </Link>
        </button>
      </div>
    </section>
  )
}

export default CTA
