import React from "react"
import viewFiles from "../../images/cell-files.svg"
import viewChart from "../../images/cell-view-chart.svg"
import addChart from "../../images/cell-add-chart.svg"
import cluster from "../../images/cluster2.svg"

const Showcase = () => {
  return (
    <section>
      <div className="lg:text-center mb-16">
        <p className="text-base text-center leading-6 text-gray-500 font-semibold tracking-wide uppercase">
          What is Explot anyways?
        </p>
        <h3 className="mt-2 text-center tracking-widest text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Cells
        </h3>
        <p className="mt-4 text-center mx-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
          A big part of explot is the concept of having small, independent
          instances (cells) of data which can't be modified externally.
        </p>
      </div>

      <ul className="mx-auto md:w-4/5 grid grid-cols-1 row-gap-12 md:grid-cols-2 md:row-gap-32 md:col-gap-8">
        <li className="p-10 bg-light-blue">
          <i></i>
          <h2 className="flex items-center">
            <svg
              className="fill-current h-6 w-6 mr-2 text-normal"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill=""
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 3C3.89543 3 3 3.89543 3 5V7C3 8.10457 3.89543 9 5 9H7C8.10457 9 9 8.10457 9 7V5C9 3.89543 8.10457 3 7 3H5Z" />
              <path d="M5 11C3.89543 11 3 11.8954 3 13V15C3 16.1046 3.89543 17 5 17H7C8.10457 17 9 16.1046 9 15V13C9 11.8954 8.10457 11 7 11H5Z" />
              <path d="M11 5C11 3.89543 11.8954 3 13 3H15C16.1046 3 17 3.89543 17 5V7C17 8.10457 16.1046 9 15 9H13C11.8954 9 11 8.10457 11 7V5Z" />
              <path d="M11 13C11 11.8954 11.8954 11 13 11H15C16.1046 11 17 11.8954 17 13V15C17 16.1046 16.1046 17 15 17H13C11.8954 17 11 16.1046 11 15V13Z" />
            </svg>
            Cells - What are they
          </h2>
          <p>
            Cells are the building blocks of explot. They take the data or files
            that you give them and store a copy of them inside, ensuring that
            the original data isn't altered or changed. Anything you do inside a
            cell will only affect that cell.
          </p>
        </li>
        <li className="my-auto px-2 md:mx-4 py-4 min-h-64">
          <img src={viewFiles} className="shadow-custom mb-0" />
          <p className="mt-3 text-base text-center mt-0 text-gray-500 sm:text-lg sm:mx-auto md:text-sm lg:mx-0">
            A Cell with 6 different data files.
          </p>
        </li>
        <li className="my-auto md:mx-4">
          <img src={addChart} className="shadow-custom" />
        </li>
        <li className="bg-light-green p-10 transition duration-500 ease-in-out hover:bg-light-blue py-16">
          <i></i>
          <h2 className="flex items-center">
            <svg
              className="h-6 w-6 mr-2 text-normal"
              stroke="currentColor"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.66347 17H14.3364M11.9999 3V4M18.3639 5.63604L17.6568 6.34315M21 11.9999H20M4 11.9999H3M6.34309 6.34315L5.63599 5.63604M8.46441 15.5356C6.51179 13.5829 6.51179 10.4171 8.46441 8.46449C10.417 6.51187 13.5829 6.51187 15.5355 8.46449C17.4881 10.4171 17.4881 13.5829 15.5355 15.5356L14.9884 16.0827C14.3555 16.7155 13.9999 17.5739 13.9999 18.469V19C13.9999 20.1046 13.1045 21 11.9999 21C10.8954 21 9.99995 20.1046 9.99995 19V18.469C9.99995 17.5739 9.6444 16.7155 9.01151 16.0827L8.46441 15.5356Z"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Exploring and Analysing
          </h2>
          <p>
            Data inside the cell can be aggregated and moved around as you see
            fit. You can analyse each column by their min,max and many other
            aggreations and see the frequency of each data type, most common
            types and scan for missing inputs.
          </p>
        </li>
        <li className="p-10">
          <h2 className="flex items-center">
            <svg
              className="h-6 w-6 mr-2 text-normal"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Visualizing
          </h2>
          <p>
            Once you have familiarized yourself with the data inside the cell
            you can visualize it and start drawing conclusions from your data
            exploration. Explot supports all the common chart types making sure
            you can always see the data in the best way you see fit.
          </p>
        </li>
        <li className="bg-light-blue p-2 md:p-10 my-auto">
          <img src={viewChart} className="shadow-custom" />
        </li>
        <li className="px-2 md:p-10">
          <img src={cluster} className="shadow-custom" />
        </li>
        <li className="p-10 my-auto">
          <h2 className="flex items-center">
            <svg
              className="h-6 w-6 mr-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.05493 11H5C6.10457 11 7 11.8954 7 13V14C7 15.1046 7.89543 16 9 16C10.1046 16 11 16.8954 11 18V20.9451M8 3.93552V5.5C8 6.88071 9.11929 8 10.5 8H11C12.1046 8 13 8.89543 13 10C13 11.1046 13.8954 12 15 12C16.1046 12 17 11.1046 17 10C17 8.89543 17.8954 8 19 8L20.0645 8M15 20.4879V18C15 16.8954 15.8954 16 17 16H20.0645M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            The Bigger Picture
          </h2>
          <p>
            It's possible to combine mulitple cells into a cluster of cells.
            These provide a dashboard like view of your data letting you draw
            bigger insights from clustering together smaller insights.
          </p>
          <p className="mt-3 text-base mt-0 text-gray-600 sm:text-lg sm:mx-auto md:text-sm lg:mx-0">
            For example having one cell about customer purchase insights, one
            with website page views and one with server performance could
            together build a dashboard serving a unique need about server
            stability affecting purchase patterns.
          </p>
        </li>
        <li className="border-2 p-10">
          <i></i>
          <h2>Not just charts</h2>
          <p>
            With explot you can also export the data! If the chart looks just
            the way you want it too after cleaning and wrangling your data you
            can export the data file, creatinga new copy of your old data.
          </p>
        </li>
        <li className="bg-light-green p-10 transition duration-500 ease-in-out hover:bg-light-blue">
          <img src="" className="hidden" />
        </li>
      </ul>
    </section>
  )
}

export default Showcase
