import React, { useState } from "react"
import pm from "../../images/undraw/productmanager.svg"
import bidev from "../../images/undraw/bideveloper.svg"
import analyst from "../../images/undraw/analyst.svg"
import finance from "../../images/undraw/finance.svg"
import other from "../../images/undraw/other.svg"

/**
 * Thoughts
 * Add a modal here where users can choose a profession and then show a text displaying why they should use it
 * IE As a Product Manager I want to quickly mockup some data to base my decision on
 * As a Analyst I want to explore the raw data before i use any other tool to create a dashboard or report
 */
const WhyUse = () => {
  const [role, setRole] = useState("product-manager")
  const bgImg =
    role === "product-manager"
      ? pm
      : role === "bi-dev"
      ? bidev
      : role === "analyst"
      ? analyst
      : role === "finance"
      ? finance
      : role === "startups"
      ? other
      : pm
  return (
    <section
      className="h-128 max-h-full px-2 py-4 border-2 bg-local bg-left bg-contain bg-no-repeat bg-opacity-75"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      {/*<img src={bidev} className="float-left object-none z-0 absolute" />*/}
      <nav className="mx-auto z-10 mt-8 md:mt-0 py-4 md:py-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center mx-auto">
              <div className="block">
                <div className="flex flex-col md:flex-row flex-wrap items-baseline font-sans">
                  <button
                    onClick={() => setRole("product-manager")}
                    className="rounded-full underline py-1 px-4 text-base tracking-wider font-medium text-gray-600 mx-1 focus:text-black hover:text-black focus:outline-none focus:text-white focus:bg-dark-green hover:text-black hover:no-underline"
                  >
                    Product Management
                  </button>
                  <button
                    onClick={() => setRole("bi-dev")}
                    className="rounded-full underline py-1 px-4 text-base tracking-wider font-medium text-gray-600 mx-1 focus:text-black hover:text-black focus:outline-none focus:text-white focus:bg-dark-green hover:text-black hover:no-underline"
                  >
                    Business Intelligence
                  </button>
                  <button
                    onClick={() => setRole("analyst")}
                    className="rounded-full underline py-1 px-4 text-base tracking-wider font-medium text-gray-600 mx-1 focus:text-black hover:text-black focus:outline-none focus:text-white focus:bg-dark-green hover:text-black hover:no-underline"
                  >
                    Data Analysis
                  </button>
                  <button
                    onClick={() => setRole("finance")}
                    className="rounded-full underline py-1 px-4 text-base tracking-wider font-medium text-gray-600 mx-1 focus:text-black hover:text-black focus:outline-none focus:text-white focus:bg-dark-green hover:text-black hover:no-underline"
                  >
                    Finance
                  </button>
                  <button
                    onClick={() => setRole("startups")}
                    className="rounded-full underline py-1 px-4 text-base tracking-wider font-medium text-gray-600 mx-1 focus:text-black hover:text-black focus:outline-none focus:text-white focus:bg-dark-green hover:text-black hover:no-underline"
                  >
                    Startups
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <UsageDescription role={role} />
    </section>
  )
}

const UsageDescription = ({ role }) => {
  if (role === "bi-dev") {
    return <BIDev />
  }
  if (role === "product-manager") {
    return <ProductManager />
  }
  if (role === "analyst") {
    return <Analyst />
  }
  if (role === "finance") {
    return <Finance />
  }
  if (role === "startups") {
    return <Startups />
  }
  return <ProductManager />
}
const ProductManager = () => (
  <div className="flex px-12 my-8 border-solid border-2 py-8 w-4/5 mx-auto bg-white bg-opacity-75">
    <div className="mx-auto ml-4">
      <h3>Find new opportunities</h3>
      <p>
        Data has never been more mission critical for success than in todays
        competetive market, to gain an edge you need to think outside the box
        and gain new insights that will allow you to understand what to focus
        on.
      </p>
      <p className="italic">
        Combine your data with that of other areas to gain a leg up over the
        competition.
      </p>
    </div>
  </div>
)
const BIDev = () => (
  <div className="flex px-12 my-8 border-solid border-2 py-8 w-4/5 mx-auto bg-white bg-opacity-75">
    <div className="mx-auto ml-4">
      <h3>Run more efficiently</h3>
      <p>
        Segment and explore your data in a lean approach by having easy access
        to visualization tool allowing you to protoype the composure fast and
        easy.
      </p>
      <p className="italic">
        Work the data with explot before creating a report to get a better
        understadning of what it's actually telling you.
      </p>
    </div>
  </div>
)

const Analyst = () => (
  <div className="flex px-12 my-8 border-solid border-2 py-8 w-4/5 mx-auto bg-white bg-opacity-75">
    <div className="mx-auto ml-4">
      <h3>Analyse your data</h3>
      <p>
        Before you start crunching the numbers and creating a dashboard or
        report you can use Explot to get an overview of the raw data and look
        how the it's put together.
      </p>
      <p className="italic">
        Quickly iterate over datasets to see how they are composed.
      </p>
    </div>
  </div>
)

const Finance = () => (
  <div className="flex px-12 my-8 border-solid border-2 py-8 w-4/5 mx-auto bg-white bg-opacity-75">
    <div className="mx-auto ml-4">
      <h3>Close the books faster</h3>
      <p>Explore different company datasets to answer your questions.</p>
      <p className="italic">
        Combine the financial data from your company and draw out key metrics
        and boast tranparance by sharing within the team.
      </p>
    </div>
  </div>
)

const Startups = () => (
  <div className="flex px-12 my-8 border-solid border-2 py-8 w-4/5 mx-auto bg-white bg-opacity-75">
    <div className="mx-auto ml-4">
      <h3>Startups</h3>
      <p>
        View the full picture of your company metrics and spot eventual pitfalls
        or areas of improvements.
      </p>
      <p className="italic">
        Represent your entire business flow from user action to server
        performane.
      </p>
    </div>
  </div>
)

export default WhyUse
