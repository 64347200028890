import React from "react"

const EarlyAccess = () => {
  return (
    <>
      <h3 className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
        Sign up to get early access and try it free for 30 days
      </h3>
      <form
        className="w-full max-w-sm"
        name="early-access"
        method="POST"
        //@ts-ignore
        data-netlify="true"
      >
        <div className="flex items-center border-b border-b-2 border-dark-green py-2">
          <input
            type="hidden"
            className="hidden"
            name="form-name"
            value="early-access"
          />
          <input
            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
            type="text"
            name="email"
            placeholder="Your email"
            aria-label="Email Adress"
          />
          <input
            className="flex-shrink-0 bg-dark-green hover:bg-light-green border-dark-green hover:border-light-green hover:text-black text-sm border-4 text-white py-1 px-2 rounded"
            type="submit"
            value="Send"
          />
          <button
            className="flex-shrink-0 border-transparent border-4 text-green text-sm py-1 px-2 rounded"
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  )
}

export default EarlyAccess
