import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Features from "../components/features"
import WhyUse from "../components/why"
import GettingStarted from "../components/how"
import CTA from "../components/cta"
import Showcase from "../components/showcase"
import Pricing from "../components/pricing"
import Header from "../components/header"
import Banner from "../components/banner/banner"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Explot"
      description="A No Code Data Exploration tool. Quickly analyse and explore your data to gain new insights."
    />
    <Hero />
    <Features />
    <CTA type="notify" />
    <WhyUse />
    <GettingStarted />
    <CTA type="learn-more" />
    <Showcase />
    <Pricing />
    <footer className="h-16 w-full bg-black content-center">
      <p className="text-white pt-4 pl-4 content-center align-center font-sans">
        <svg
          className="h-3 w-3 spin inline mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM7.88 7.88l-3.54 7.78 7.78-3.54 3.54-7.78-7.78 3.54zM10 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </svg>
        For questions and inquiries e-mail: <code>contact@explot.io</code>
      </p>
    </footer>
  </Layout>
)

export default IndexPage
